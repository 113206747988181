<template>
  <div class="shop">
    <navbarTools
      :leftArrow="true"
      :fixed="true"
      :customLeftFn="customLeftFn"
      :rightText="rightText"
      :rightFn="myOrderFn"
    ></navbarTools>
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img :src="convertMallObj.bannerUrl + '?version=2'" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="member-trends"
      :style="{
        backgroundImage: 'url(' + OSS_URL + '/shop/member-trends.png)',
      }"
    >
      <vue3-seamless-scroll :list="listData" class="scroll" :step="0.3">
        <div class="item" v-for="(item, index) in listData" :key="index">
          <span class="c-ff9601">{{ item.userName }}:</span>
          <span>{{ item.content }}</span>
        </div>
      </vue3-seamless-scroll>
    </div>
    <div class="shopBox">
      <div class="shopBtn exchange" @click="exchangeShopFn()">
        <div>
          <img :src="OSS_URL + '/shop/exchange_home.png'" />
        </div>
        <div class="content">
          <div class="name fs16">精选兑换</div>
          <div class="desc fs12">好 物 推 荐</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="shopBtn jd" @click="jdShopFn">
        <div>
          <img :src="OSS_URL + '/shop/jd_home.png'" />
        </div>
        <div class="content">
          <div class="name fs16">京东好物</div>
          <div class="desc fs12">品 质 生 活</div>
        </div>
        <div>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div class="shoptype">
      <van-grid :column-num="3">
        <van-grid-item
          v-for="item in convertMallObj.goodsCategoryList"
          :key="item.categoryIconUrl"
          @click="exchangeShopFn(item.categoryId)"
        >
          <div>
            <div>{{ item.categoryName }}</div>
            <img :src="item.categoryIconUrl" />
          </div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="hotshop">
      <div class="hotshop-title">热门商品</div>
      <div class="shoplistBox">
        <van-list
          finished-text="没有更多了"
          :immediate-check="false"
          v-model:loading="loading"
          :finished="finished"
          @load="onloadFn"
          :nullList="nullList"
        >
          <shoplist :shopList="shopList" :type="'home'"></shoplist>
        </van-list>
      </div>
    </div>
    <van-popup v-model:show="show" class="msgPopu" :close-on-click-overlay="false">
      <div class="msgBox">
        <div class="title">通知</div>
        <div class="msg fs16 c-666 pl24 pr24">
          由于临近春节物流陆续停运，本平台订单将于<span>1月20日</span>起<span>停止发货！2月10日</span>起按下单时间陆续发货，非常抱歉因此给您带来不便，祝您新春快乐，阖家幸福！
        </div>
        <div class="iknow" @click="show = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  onActivated,
  onMounted,
} from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import shoplist from "@/components/shoplist";
import { ua } from "@/utils/ua";
import { convertMallIndex, IndexGoodsList } from "@/api/shop";
export default defineComponent({
  components: {
    navbarTools,
    Vue3SeamlessScroll,
    shoplist,
  },
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    let app = getCurrentInstance();
    let userInfo = null;
    if (ua.isAndroidApp || ua.isIosApp) {
      userInfo = app.proxy.$bridge.handleMethods("getUserInfo", (rs) => {
        return rs;
      });
    }
    const state = reactive({
      OSS_URL: process.env.VUE_APP_OSS_URL,
      userInfo,
      convertMallObj: {},
      listData: [],
      pages: {
        page: 1,
        pageSize: 10,
      },
      shopList: [],
      loading: false,
      finished: false,
      rightText: "我的订单",
      nullList: true,
      show: false,
    });
    const exchangeShopFn = (id = "") => {
      $router.push({
        path: "/exchangeshop",
        query: {
          id,
        },
      });
    };
    const myOrderFn = () => {
      $router.push({
        path: "/shop/myorder",
      });
    };
    const jdShopFn = () => {
      $router.push({
        path: "/jdshop",
      });
    };

    const orderFn = () => {
      $router.push({
        path: "/shop/myorder",
      });
    };
    const convertMallIndexFn = async () => {
      let rs = await convertMallIndex();
      state.convertMallObj = rs.data;
      state.listData = rs.data.indexUserDynamicsList;
    };
    const IndexGoodsListFn = async () => {
      let rs = await IndexGoodsList(state.pages);
      state.shopList = [...state.shopList, ...rs.data.list];
      if (state.shopList.length > 0) {
        state.nullList = true;
      } else {
        state.nullList = false;
      }
      if (state.pages.page >= rs.data.totalpage) {
        state.finished = true;
      } else {
        state.loading = false;
      }
    };
    const onloadFn = () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        IndexGoodsListFn();
      }, 1000);
    };
    const customLeftFn = () => {
      if (ua.isAndroidApp || ua.isIosApp) {
        app.proxy.$bridge.handleMethods("quitWebview");
      } else {
        $router.go(-1);
      }
    };
    $router.afterEach((to, from) => {
      if (
        from.path == "/exchangeshop/goodsdetail" ||
        from.path == "/jdshop/goodsDetail"
      ) {
        to.meta.isUseCache = false;
      } else {
        to.meta.isUseCache = true;
      }
    });
    onActivated(() => {
      if ($route.meta.isUseCache) {
        state.listData = [];
        state.pages = {
          page: 1,
          pageSize: 10,
        };
        state.shopList = [];
        state.loading = false;
        state.finished = false;
        convertMallIndexFn();
        IndexGoodsListFn();
      }
    });
    onMounted(() => {
      let date = localStorage.getItem("date");
      let currentDate = `${new Date().getMonth() + 1}-${new Date().getDate()}`;
      if (new Date().getTime() < new Date("2022-02-10").getTime()) {
        if (date != currentDate) {
          state.show = true;
          localStorage.setItem("date", currentDate);
        }
      }
    });
    return {
      ...toRefs(state),
      exchangeShopFn,
      jdShopFn,
      orderFn,
      convertMallIndexFn,
      IndexGoodsListFn,
      onloadFn,
      customLeftFn,
      myOrderFn,
    };
  },
});
</script>

<style lang="scss" scoped>
.shop {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .banner {
    width: 343px;
    height: 118px;
    position: relative;
    top: 56px;
    left: 15px;
    .van-swipe {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .shopBox {
    position: relative;
    top: 73px;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;

    .shopBtn {
      width: 175px;
      height: 59px;
      background: linear-gradient(150deg, #10dcf9 0%, #05b6f9 100%);
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.07);
      border-radius: 6px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 80px;
        line-height: 20px;
        .desc {
          font-weight: 400;
        }
      }
      img {
        width: 37px;
        height: 37px;
        vertical-align: middle;
      }
      span {
        padding-left: 5px;
      }
    }
    .jd {
      background: linear-gradient(146deg, #ff8181 0%, #ff2b2b 100%);
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.07);
    }
  }
  .member-trends {
    width: 359px;
    height: 81px;
    background-repeat: no-repeat;
    background-size: 359px 81px;
    position: relative;
    top: 65px;
    left: 8px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);

    .scroll {
      height: 75px;
      width: 100%;
      overflow: hidden;
      padding: 20px 10px 10px 40px;
      .item {
        margin-top: 5px;
      }
      .c-ff9601 {
        color: #ff9601;
      }
    }
  }
  .shoptype {
    width: 359px;
    height: 193px;
    position: relative;
    top: 80px;
    left: 8px;
    font-size: 14px;
    color: #fff;
    background: linear-gradient(
      180deg,
      #fc7301 0%,
      rgba(255, 255, 255, 0.37) 60%
    );
    border-radius: 6px;
    img {
      width: 98px;
      height: 59px;
      padding-top: 5px;
    }
    ::v-deep(.van-grid) {
      height: 193px;
      .van-grid-item {
        .van-grid-item__content {
          background: transparent;
          padding: 0px;
        }
        .van-hairline::after {
          border-top: none;
          top: -40%;
        }
      }
      .van-grid-item:nth-child(n + 4) {
        color: #333333;
      }
    }
  }
  .hotshop {
    position: relative;
    top: 86px;
    .hotshop-title {
      font-size: 16px;
      font-weight: 600;
      padding: 0px 10px;
    }
  }
  ::v-deep(.msgPopu) {
    border-radius: 10px;
    .msgBox {
      width: 290px;
      height: 315px;
      background: #ffffff;
      border-radius: 10px;
      .title {
        font-weight: 600;
        font-size: 19px;
        text-align: center;
        padding-top: 24px;
      }
      .msg {
        line-height: 32px;
        padding-top: 20px;

        span {
          color: #fc7301;
        }
      }
      .iknow {
        width: 240px;
        height: 43px;
        background: #fc7301;
        border-radius: 6px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        line-height: 43px;
        margin-left: 25px;
        margin-top: 20px;
      }
    }
  }
}
</style>
